/*
Font-Faces for use with font-family:
"PT Mono"
"PT Sans normal"
"PT Sans bold"
"PT Serif normal"
"PT Serif bold"
*/

@font-face {
    font-family: "PT Mono";
    src: url("/fonts/PT_Mono_400.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: "PT Sans normal";
    src: url("/fonts/PT_Sans_400.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: "PT Sans bold";
    src: url("/fonts/PT_Sans_700.ttf") format("truetype");
    font-style: normal;
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: "PT Serif normal";
    src: url("/fonts/PT_Serif_400.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: "PT Serif bold";
    src: url("/fonts/PT_Serif_700.ttf") format("truetype");
    font-style: normal;
    font-weight: bold;
    font-display: swap;
}
