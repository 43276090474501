// *** Predefinitions ***
//$theme-colors: (
//	"primary": #FF8800,
//	"secondary": #0077BB,
//	"success": #66BB00,
//	"danger": #CC0000,
//	"warning": #FFB800,
//	"info": #1984c2,
//	"light": #f8f8f8,
//	//"dark": #333
//	"dark": rgb(36,49,67),
//	"medium": #777
//);

// *** Fonts
@import "fonts.scss";

// *** Bootstrap
@import "bootstrap/scss/bootstrap.scss";

.display-1 {
	font-family: "PT Sans bold";
	font-size: 50px;
};

.display-2 {
	font-family: "PT Sans bold";
	font-size: 39px;
};

.display-3 {
	font-family: "PT Sans bold";
	font-size: 28px;
};

.display-4 {
	font-family: "PT Sans bold";
	font-size: 17px;
};

body {
	font-family: "PT Sans normal";
	font-size: 16px;
};

h1 {
	font-family: "PT Sans bold";
	font-size: 28px;
	line-height: 32px;
}

h2 {
	font-family: "PT Sans bold";
	font-size: 24px;
	line-height: 26px;
}

h3 {
	font-family: "PT Sans bold";
	font-size: 20px;
	line-height: 23px;
}

h4 {
	font-family: "PT Sans bold";
	font-size: 18px;
	line-height: 20px;
}

strong {
	font-family: "PT Sans bold";
}

.card-header {
	font-family: "PT Sans bold";
	font-size: 16pt;
}

.card-footer {
	font-family: "PT Sans normal";
	font-size: 10pt;
}

.footer {
	font-family: "PT Sans normal";
	font-size: 10pt;
}

.mspace {
    font-family: "PT Mono";
}

.content {
	font-family: "PT Sans normal";
	font-size: 16px;
}